jQuery(document).ready(function($) {
	
	(function () {
		var player;
		if ($('#yt-player').length > 0) {
			var tag = document.createElement('script');
			tag.src = "//www.youtube.com/player_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

			var ytid = $('#yt-player').attr('data-id');

			window.onYouTubePlayerAPIReady = function () {
				// create the global player from the specific iframe (#video)
				player = new YT.Player('yt-player', {
					videoId: ytid,
					height: '960',
					width: '540',
					playerVars: {
						origin: window.location.origin,
						autoplay: 0,        // Auto-play the video on load
						autohide: 1,
						disablekb: 1,
						controls: 0,        // Hide pause/play buttons in player
						showinfo: 0,        // Hide the video title
						modestbranding: 1,  // Hide the Youtube Logo
						loop: 0,            // Run the video in a loop
						fs: 0,              // Hide the full screen button
						autohide: 0,         // Hide video controls when playing
						rel: 0,
						enablejsapi: 1
					},
					events: {
						onStateChange: onPlayerStateChange
					}
				});
			}
		}

		function play() {
			player.playVideo();
		}

		function stop() {
			player.stopVideo();
		}

		function onPlayerStateChange(event) {
			if (event.data === 0) {
				player.seekTo(0);
				stop();
			}
		}
	})();


	
	(function() {
		if ($('.video-container').length > 0) {
			var player;
			var tag = document.createElement('script');
			tag.src = "//www.youtube.com/player_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

			var ytid = $('#ytvideo').attr('data-id');

			window.onYouTubePlayerAPIReady = function () {
				// create the global player from the specific iframe (#video)
				player = new YT.Player('ytvideo', {
					videoId: ytid,
					width: '100%',
					height: 820,
					playerVars: {
						autoplay: 1,        // Auto-play the video on load
						autohide: 1,
						disablekb: 1,
						controls: 0,        // Hide pause/play buttons in player
						showinfo: 0,        // Hide the video title
						modestbranding: 1,  // Hide the Youtube Logo
						loop: 1,            // Run the video in a loop
						fs: 0,              // Hide the full screen button
						autohide: 0,         // Hide video controls when playing
						rel: 0,
						enablejsapi: 1
					},
					events: {
						'onReady': onPlayerReady,
						'onStateChange': onPlayerStateChange
					}
				});
			}

			function onPlayerReady() {
				player.mute();
				player.playVideo();
			}

			function onPlayerStateChange(event) {
				if (event.data == YT.PlayerState.PLAYING) {
					$('.video-embed').addClass('no-filter');
					playing = true;
				}

				else if (event.data == YT.PlayerState.PAUSED) {
					$('.video-button').fadeIn();
					$('.video-intro').fadeIn();
					playing = false;
				}
			}

			$('.video-button').on('click', function () {
				$(this).fadeOut();
				$('.video-intro').fadeOut();
				player.unMute();
				player.seekTo(0);
				player.playVideo();
			});
		}
	})();
});

