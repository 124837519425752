jQuery(document).ready(function ($) {

	if (!$(".showcase-carousel.slick-initialized").length) {
		$('.showcase-carousel').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: true,
			adaptiveHeight: true
		});
	}
	if (!$(".js-exhibitions-carousel.slick-initialized").length) {
		$('.js-exhibitions-carousel').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true
		});
	}

	$('.full-card-carousel').slick({
		lazyLoad: 'ondemand',
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true
	});

	$('.post-type-archive-shows .upcoming-shows, .page-show-archive .upcoming-shows, .post-type-archive-satrelease .upcoming-shows, .show-archive-gallery ul').slick({
		lazyLoad: 'ondemand',
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		dots: true,
		arrows: true
	});

	$('.gallery-carousel').slick({
		lazyLoad: 'ondemand',
		centerMode: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		cssEase: 'linear',
		variableWidth: true,
		variableHeight: true,
		autoplay: true,
		autoplaySpeed: 2500
	});






	$('.js-three-card-carousel').slick({
		lazyLoad: 'ondemand',
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				}
			}
		]
	});



	$('.js-insta-carousel').slick({
		lazyLoad: 'ondemand',
		slidesToShow: 6,
		slidesToScroll: 5,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.artwork-gallery').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		variableWidth: false,
		arrows: false
	});


	if ($(window).width() < 767) {
		if ($('.shows-archive-carousel').length) {
			$('.shows-archive-carousel').slick('unslick');
		}
	} else {
		if (!$('.shows-archive-carousel.slick-initialized').length) {
			$('.shows-archive-carousel').slick({
				lazyLoad: 'ondemand',
				infinite: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				dots: false,
				variableWidth: true,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
							dots: false
						}
					}
				]
			});
		}
	}


	function destroySlick() {
		if ($(window).width() < 992) {
			if ($('.js-instafeed-carousel.slick-initialized').length) {
				$('.js-instafeed-carousel').slick('unslick');
			}
			if ($('.js-four-card-carousel.slick-initialized').length) {
				$('.js-four-card-carousel').slick('unslick');
			}
			if ($('.js-five-card-carousel.slick-initialized').length) {
				$('.js-five-card-carousel').slick('unslick');
			}
			if ($('.js-six-card-carousel.slick-initialized').length) {
				$('.js-six-card-carousel').slick('unslick');
			}
			if ($('.artist-carousel .carousel.slick-initialized').length) {
				$('.artist-carousel .carousel').slick('unslick');
			}
		}
		else {
			if (!$(".js-four-card-carousel.slick-initialized").length) {
				$('.js-four-card-carousel').slick({
					lazyLoad: 'ondemand',
					slidesToShow: 4,
					slidesToScroll: 2,
					speed: 700
				});
			}

			if (!$(".js-instafeed-carousel.slick-initialized").length) {
				$('.js-instafeed-carousel').slick({
					lazyLoad: 'ondemand',
					slidesToShow: 6,
					slidesToScroll: 1,
					centerMode: false,
					dots: false,
					infinite: false,
					speed: 700,
					responsive: [
						{
							breakpoint: 1900,
							settings: {
								slidesToShow: 6
							}
						},
						{
							breakpoint: 1450,
							settings: {
								slidesToShow: 5
							}
						},
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: 4
							}
						},
						{
							breakpoint: 992,
							settings: {
								slidesToShow: 3
							}
						},
						{
							breakpoint: 750,
							settings: {
								slidesToShow: 3
							}
						}
					]
				});
			}

			if (!$(".js-five-card-carousel.slick-initialized").length) {
				$('.js-five-card-carousel').slick({
					lazyLoad: 'ondemand',
					slidesToShow: 7.5,
					slidesToScroll: 5,
					centerMode: false,
					dots: false,
					infinite: false,
					speed: 1200,
					responsive: [
						{
							breakpoint: 1900,
							settings: {
								slidesToShow: 5.5
							}
						},
						{
							breakpoint: 1450,
							settings: {
								slidesToShow: 4.5
							}
						},
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: 4.5
							}
						},
						{
							breakpoint: 992,
							settings: {
								slidesToShow: 3.5
							}
						},
						{
							breakpoint: 750,
							settings: {
								slidesToShow: 2.5
							}
						}
					]
				});
			}


			if (!$(".js-six-card-carousel.slick-initialized").length) {
				$('.js-six-card-carousel').slick({
					lazyLoad: 'ondemand',
					infinite: true,
					slidesToShow: 6,
					slidesToScroll: 3,
					speed: 1200,
					responsive: [
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 2,
							}
						},
						{
							breakpoint: 992,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 1
							}
						},
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1
							}
						}
					]
				});
			}

			if (!$(".artist-carousel .carousel.slick-initialized").length) {
				$('.artist-carousel .carousel').slick({
					lazyLoad: 'ondemand',
					centerMode: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false,
					arrows: true,
					cssEase: 'linear',
					variableWidth: true,
					variableHeight: true,
					autoplay: true,
					autoplaySpeed: 5000,
					responsive: [
						{
							breakpoint: 1311,
							settings: {
								centerMode: false
							}
						}
					]
				});
			}
		}
	}

	function generateHomepageCarousels() {
		if ($(".homepage-hero__carousel.slick-initialized").length) {
			$('.homepage-hero__carousel ').slick('unslick');
		}
		if ($(".homepage-hero__small-carousel.slick-initialized").length) {
			$('.homepage-hero__small-carousel').slick('unslick');
		}

		if (!$(".homepage-hero__carousel.slick-initialized").length) {
			$('.homepage-hero__carousel ').slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				speed: 500
			});
		}

		if (!$(".homepage-hero__small-carousel.slick-initialized").length) {
			$('.homepage-hero__small-carousel').slick({
				infinite: true,
				slidesToShow: 3,
				slidesToScroll: 3,
				dots: true,
				speed: 1200,
			});
		}
	}


	function mergeHomepageCarousels() {

		if ($(window).width() < 992) {

			if ($(".homepage-hero__carousel.slick-initialized").length) {
				$('.homepage-hero__carousel ').slick('unslick');
			}
			if ($(".homepage-hero__small-carousel.slick-initialized").length) {
				$('.homepage-hero__small-carousel').slick('unslick');
			}

			var endelement;
			$.each($('.homepage-hero__row'), function (index, value) {
				if (index == 0)
					endelement = $(this);
				else {
					$.each($(this).children('.col-12'), function (i, v) {
						endelement.append($(this));
					});
				}
			});

			generateHomepageCarousels();

		} else {

			if ($(".homepage-hero__carousel.slick-initialized").length) {
				if( $(".homepage-hero__carousel.slick-initialized .slick-slide").length > 3 ) {
					$('.homepage-hero__carousel').slick('unslick');
					var slides = $('.homepage-hero__row').children('.col-12:gt(2)');
					$('.homepage-hero__small-carousel').append(slides);
				}
			}

			generateHomepageCarousels();
		}
	}

		//generateHomepageCarousels();
	mergeHomepageCarousels();
	destroySlick();

	var width = $(window).width(), height = $(window).height();

	$(window).resize(function () {
		if($(window).width() != width || $(window).height() != height){
			destroySlick();
			mergeHomepageCarousels();
		}
	});
});