/* Custom JavaScript belongs in this file and will compile into a minified file */

jQuery(document).ready(function ($) {
    AOS.init();

    var colourListFilter = $(".wcpf-field-color-list");
    var orderFilter = $(".woocommerce-ordering");

    colourListFilter.after(orderFilter);

    if ($('header.product .outstock').length) {
        $('.wcppec-checkout-buttons').css({
            display: 'none',
        });
    }


    var $div = $(".woocommerce-product-gallery__image");
    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.attributeName === "class") {
                var index = $(mutation.target).index();

                var $flexThumbsLi = $('.flex-control-thumbs li');
                var $flexThumbsLiImg = $flexThumbsLi.find('img');

                $flexThumbsLi.removeClass('active');
                $flexThumbsLiImg.removeClass('flex-active');

                $flexThumbsLi.eq(index).addClass('active');
                $flexThumbsLiImg.eq(index).addClass('flex-active');
            }
        });
    });


    $('.woocommerce-product-gallery__image').each(function () {
        observer.observe($(this)[0], {
            attributes: true
        });
    });


    var vimeoIframe = $("#gallery-video iframe");

    setTimeout(function () {
        $('.flex-control-thumbs li img').each(function () {
            if ($(this).hasClass('flex-active')) {
                $(this).parent('li').addClass('active');
            } else {
                $(this).parent('li').removeClass('active');
            }
        });

        $('.flex-control-thumbs').find('li').on('touchend click', function () {
            $('.flex-control-thumbs li').removeClass('active');
            $(this).addClass('active');
        });

        // $('.flex-control-thumbs').find('li').on('touchend click', function () {
        //     if ($('.single-product__video-gallery').hasClass('active')) {
        //         $('.single-product__video-gallery').removeClass('active').hide();
        //         $('.woocommerce-product-gallery').flexslider(0);
        //         $('.flex-viewport').fadeIn();

        //         console.log('here');
        //     }

        // });

    }, 300);

    $('ul.view-dropdown').on('click', '.init', function () {
        $(this).closest("ul").children('li:not(.init)').toggle();
    });

    var allOptions = $('ul.view-dropdown').children('li:not(.init)');
    $('ul.view-dropdown').on('click', 'li:not(.init)', function () {
        allOptions.removeClass('selected');
        $(this).addClass('selected');
        $('ul.view-dropdown').children('.init').html($(this).html());
        allOptions.toggle();
    });

    $(".view-dropdown li:not(.init)").click(function () {
        let roomID = $(this).attr('data-room');
        $(".colour-choice").css("display", "none");
        $("#" + roomID).css("display", "inline-flex");
        let firstRoomImage = $("#" + roomID + ' li').first().attr("data-room-img");
        $(".single-product__view-room").css("background-image", 'url(' + firstRoomImage + ')');
    });

    let viewRoom = $(".single-product__view-room");
    let colourChoice = $(".colour-choice li");

    colourChoice.click(function () {
        let roomImage = $(this).attr("data-room-img");
        viewRoom.css("background-image", 'url(' + roomImage + ')');
    });


    if ($(".js-countdown-timer").length) {     // COUNTDOWN TIMER
        $(".js-countdown-timer").each(function () {
            createCountdown($(this));
        });
    }


    function createCountdown($timer) {

        // Set the date we're counting down to
        // var countDownDate = new Date("Jan 1, 2019 12:00:00").getTime();
        var getCountDownDate = $timer.attr("data-time");
        var getCountDownMessage = $timer.attr("data-final");
        var countDownDate = new Date(getCountDownDate).getTime();

        var daysTicker = $timer.find('#js-days-ticker');
        var hoursTicker = $timer.find('#js-hours-ticker');
        var minutesTicker = $timer.find('#js-minutes-ticker');
        var secondsTicker = $timer.find('#js-seconds-ticker');

        // Update the count down every 1 second
        var x = setInterval(function () {

            // Get todays date and time
            var now = new Date().getTime();

            // Find the distance between now an the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            //Zeros
            var hours = (hours.toLocaleString(undefined, { minimumIntegerDigits: 2 }));
            var minutes = (minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 }));
            var seconds = (seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 }));

            // Display the result in the element with id="demo"
            if (daysTicker.length) {
                daysTicker.html(days);
            }
            if (hoursTicker.length) {
                hoursTicker.html(hours);
            }
            if (minutesTicker.length) {
                minutesTicker.html(minutes);
            }
            if (secondsTicker.length) {
                secondsTicker.html(seconds);
            }

            if (distance < 0) {
                if (getCountDownMessage.length > 0) {
                    $timer.addClass('complete');
                    $timer.text(getCountDownMessage);
                } else {
                    $timer.hide();
                }

            }

        }, 1000);
    }


    // HERO SLICK

    function heroCarousel() {

        if ($(window).width() < 992) {

            // var lastBlock = $(".last-block").detach();
            // $('.js-remove-parent').after(lastBlock);

            if (!$(".homepage-hero__wrap.slick-initialized").length) {
                $('.homepage-hero__wrap').slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    adaptiveHeight: true
                });
            }

            if ($('.homepage-hero__wrap.slick-initialized').length) {
                $('.homepage-hero__wrap')[0].slick.refresh();
            }
        }

        // } else {

        //     if ($(".homepage-hero__wrap.slick-initialized").length) {
        //         $('.homepage-hero__wrap').slick('unslick');
        //     }

        //     var putBlockBack = $(".last-block").detach();
        //     $('.js-remove-parent').append(putBlockBack);

        //     // $('.homepage-hero__wrap').slick('unslick');

        // }
    }

    var initialWidth = $(window).width();

    $(window).resize(function () {
        var newWidth = $(window).width();

        if (newWidth != initialWidth) {
            initialWidth = newWidth
            heroCarousel();
        }
    });

    heroCarousel();








    if ($('.vimeo-video').length > 0) {
        var iframe = document.querySelector('iframe');
        var player = new Vimeo.Player(iframe);
    }

    // setTimeout(function () {
    //     if ($("#slick-custom-next").length > 0 && $('.flex-control-nav').length > 0) {
    //         var nextButton = $('#slick-custom-next')[0].outerHTML;
    //         $('#slick-custom-next').remove();
    //         $('.flex-control-nav').append(nextButton);
    //         console.log('detected');
    //     }
    // }, 400);

    setTimeout(function () {
    
            if( $('.flex-control-thumbs').length ) {

                if($(window).width() > 992) {
                    var count = $('.flex-control-thumbs li').length;
                    if(count > 5) {
                        $('.flex-control-thumbs').addClass('scroll');

                        $('.flex-control-thumbs.scroll').on('init', function(event, slick){
                            $('.flex-control-nav .slick-prev').css({'pointer-events':'none', 'opacity':'0'});
                        });

                        $('.flex-control-thumbs.scroll').on('beforeChange', function(slick, index){
                            $('.flex-control-nav .slick-prev').css({'pointer-events':'all', 'opacity':'1'});
                        });
    
                        $('.flex-control-thumbs.scroll').slick({ 
                            lazyLoad: 'ondemand',
                            infinite: true,
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            speed: 1200,
                            vertical: true,
                            // nextArrow: $('#slick-custom-next'),
                            responsive: [
                                {
                                    breakpoint: 1200,
                                    settings: {
                                        slidesToShow: 4,
                                        slidesToScroll: 2,
                                    }
                                },
                                {
                                    breakpoint: 992,
                                    settings: {
                                        slidesToShow: 4,
                                        slidesToScroll: 1
                                    }
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1
                                    }
                                }
                            ]
                        });
                    }
                }
            }


        // Gallery Video
        // $('.single-product__video').on('touchend click', function (e) {
        //     alert('test');
        //     $('.flex-viewport').fadeOut();
        //     $('.single-product__video-gallery').addClass('active').show();
        //     if ($('.vimeo-video').length > 0) {
        //         player.play();
        //     }
        //     $('.woocommerce-product-gallery__wrapper div').removeClass('flex-active-slide');
        //     e.preventDefault();
        // });

        // $('.flex-control-thumbs').find('li').on('touchend click', function () {
        //     if (!$('.single-product__video-gallery').hasClass('active')) {
        //         if ($('.vimeo-video').length > 0) {
        //             player.pause();
        //         }
        //         $('.woocommerce-product-gallery').flexslider(0);
        //     }
        // });
    }, 300); 



    function createCardCarousel() {
        $('#gift-carousel').slick({
            lazyLoad: 'ondemand',
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }


    // ARTISTS
    if ($('.artist-fave').length) {
        var myFaves = readCookie('artistfaves');

        $('.artist-fave').each(function () {
            var artistHeart = $(this);
            var artist = artistHeart.attr('data-artist');

            if (myFaves) {
                var faveArray = myFaves.split(",");
                var SearchIndex = faveArray.indexOf(artist);

                if (SearchIndex != -1) {
                    artistHeart.addClass('favourite');
                }
            }
        });
    }

    $('.artist-fave').on('click', function (e) {
        var artistHeart = $(this);
        var myFaves = readCookie('artistfaves');
        var artist = artistHeart.attr('data-artist');
        if (myFaves) {
            var faveArray = myFaves.split(",");
            var SearchIndex = faveArray.indexOf(artist);

            if (SearchIndex != -1) {
                myFaves = removeValue(myFaves, artist, ',');
                artistHeart.removeClass('favourite');
            } else {
                myFaves += ',' + artist;
                artistHeart.addClass('favourite');
            }

        } else {
            myFaves = artist;
            artistHeart.addClass('favourite');
        }
        createCookie('artistfaves', myFaves, 365);
        return false;
    });

    function removeValue(list, value, separator) {
        separator = separator || ",";
        var values = list.split(separator);
        for (var i = 0; i < values.length; i++) {
            if (values[i] == value) {
                values.splice(i, 1);
                return values.join(separator);
            }
        }
        return list;
    }

    if ($('.artist-nav-alpha').length) {
        var artistNav = $('.artist-nav-alpha').offset().top;

        $(window).scroll(function () {
            if ($(window).scrollTop() > artistNav - ($('header').height() + 50)) {
                $('.artist-nav-alpha-holder').addClass('fixed');
                $('.artist-grid.first-group').css('margin-top', $('.artist-nav-alpha').outerHeight());
            }
            else {
                $('.artist-nav-alpha-holder').removeClass('fixed');
                $('.artist-grid.first-group').css('margin-top', 0);
            }
        });

        $('.artist-nav-alpha a').on('click', function (event) {
            $('.artist-nav-alpha li').removeClass('active');
            $(this).parent().addClass('active');
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - $('header').height() - 100
            }, 500);


        });


        $(".artist-nav-alpha").after("<span id='artist-highlight'></span>");

        var $artistHighlight = $("#artist-highlight");

        if ($(window).width() > 768) {
            $artistHighlight
                .css("left", $(".artist-nav-alpha .active a").position().left - 20)
                .data("origLeft", $(".artist-nav-alpha .active a").position().left);

            $(".artist-nav-alpha a").on('mouseenter', function () {
                $el = $(this);
                leftPos = $el.position().left - 20;
                $artistHighlight.stop().animate({
                    left: leftPos
                }, 300);
            });

            /* Kick IE into gear */
            $(".artist-nav-alpha .active  a").mouseenter();
        }
    }


    if ($('.artist-menu.genres').length) {
        var genreNav = $('.artist-menu.genres').offset().top;
        $(window).scroll(function () {
            if ($(window).scrollTop() > genreNav - ($('header').height() + 50)) {
                $('.artist-menu.genres').addClass('fixed');
                $('.artist-grid.first-group').css('margin-top', $('.artist-menu.genres').outerHeight());
            }
            else {
                $('.artist-menu.genres').removeClass('fixed');
                $('.artist-grid.first-group').css('margin-top', 0);
            }
        });

        $('.artist-menu.genres a').on('click', function (event) {

            $('.artist-menu.genres a').removeClass('active');
            $(this).addClass('active');
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 300
            }, 500);
        });
    }


    if ($('.artist-menu.search').length) {
        var searchNav = $('.artist-menu.search').offset().top;
        $(window).scroll(function () {
            if ($(window).scrollTop() > searchNav - ($('header').height() + 50)) {
                $('.artist-menu.search').addClass('fixed');
                //$('.artist-grid.first-group').css('margin-top', $('.artist-menu.genres').outerHeight());
            }
            else {
                $('.artist-menu.search').removeClass('fixed');
                //$('.artist-grid.first-group').css('margin-top', 0);
            }
        });

        $('.artist-menu.search a').on('click', function (event) {

            $('.artist-menu.search a').removeClass('active');
            $(this).addClass('active');
            event.preventDefault();

            

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 250
            }, 500);
        });
    }


    if ($('.artist-tabs-menu').length) {
        var searchNav = $('.artist-tabs-menu').offset().top;

        var offset = 25;
        if($(window).width() <= 768) {
            offset = 70;
        }

        var pt = 167;
        if ($(window).width() < 992 && $(window).width() > 768) {
            pt = 142;
        } else if($(window).width() <= 768 && $(window).width() >= 481) {
            pt = 120;
        } else {
            pt = 100;
        }

        console.log(searchNav);
        console.log(searchNav - ($('header').height() + $('.archive-banner').height() + offset));

        $(window).scroll(function () {
            if ($(window).scrollTop() > (searchNav - ($('header').height() + offset))) {
                $('.artist-tabs-menu').addClass('fixed');
                $('.site-content').css('padding-top', ''+pt+'px');
            }
            else {
                $('.artist-tabs-menu').removeClass('fixed');
                $('.site-content').css('padding-top', '');
            }
        });
    }

    setTimeout(function () {
        if ($('.woocommerce-product-gallery').length > 0) {
            $share = $('.product').find('.share-button');
            $('.woocommerce-product-gallery').append($share);
        }
        if ($('.flex-viewport').length > 0) {
            $nav = $('.product').find('.flex-direction-nav');
            $('.flex-viewport').append($nav);
        }

    }, 500);

    if ($('.artist-grid').length > 0) {
        $(window).on('load resize', function () {
            $('.artist-grid').find('.square .image-pods__item').each(function () {
                var w = $(this).width();
                $(this).height(w);
            });
        });
    }







    // AJAX GIFT CAROUSEL FILTER

    if ($('.card-carousel__filter').length) {


        $(document).on('click', '.card-carousel__filter', function (event) {

            event.preventDefault();
            var filter = $(this).val();
            var items = $('.card-carousel__filters').attr('data-items');
            giftCarousel = $("#gift-carousel");
            giftCarouselCards = $("#gift-carousel .js-carousel-cards");
            giftCarouselItem = $("#gift-carousel .card-carousel__card");

            $(".card-carousel__filter").removeClass('active');
            $(this).addClass('active');

            $.ajax({
                url: giftideas.ajaxurl,
                type: 'POST',
                data: {
                    action: 'gift_filter',
                    filter: filter,
                    items: items
                },
                beforeSend: function (xhr) {
                    giftCarouselItem.addClass('loading');
                },
            })
                .done(function (data) {
                    giftCarousel.addClass('slideFadeOut');
                    giftCarouselItem.removeClass('loading');
                    giftCarousel.slick('unslick');
                    giftCarouselCards.remove();
                    giftCarousel.append(data);
                    createCardCarousel();
                    giftCarousel.addClass('slideFadeIn');
                });
        });
    }


    // MASONRY

    var $grid = $('.product-grid .products').masonry({
        // options...
        itemSelector: '.product-grid__item',
    });

    $grid.imagesLoaded().progress(function () {
        $grid.masonry('layout');
    });

    $(document).on('yith_infs_adding_elem', function (e, elms) {
        var $h = $('.products').height() + 72;
        $('.products').css('min-height', $h + 'px');

        $(elms).each(function () {
            var $item = $(this);
            $item.imagesLoaded(function () {
                $grid.masonry('appended', $item);
            });
        });

        $('.woocommerce-loop-product__link').click(function () {
            var id = $(this).parent().attr('data-product-id');
            localStorage.setItem('last-product', id);
        });
    });

    // Scroll the user back to the product they first clicked on
    var isProductIndex = $('.product-grid').length > 0;

    if (isProductIndex && localStorage) {
        $('.woocommerce-loop-product__link').click(function () {
            var id = $(this).parent().attr('data-product-id');
            localStorage.setItem('last-product', id);
        });
        var lastProductClick = localStorage.getItem('last-product');

        $('.product-grid').imagesLoaded(function () {
            if (lastProductClick) {
                var $product = $('.post-' + lastProductClick);
                if ($product.length) {
                    var headerHeight = $('.site-header').outerHeight();
                    $([document.documentElement, document.body]).scrollTop($product.offset().top - headerHeight - 30);
                    localStorage.removeItem('last-product');
                }
            }
        });
    }


    // SHOP FILTERS
    $(".filters__button").click(function () {
        if (!$(this).hasClass("filters__button--sort")) {
            var filterType = $(this).attr('data-filter-toggle');
            var targetFilter = $('#' + filterType);

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                targetFilter.removeClass('active');
            } else {
                $(".filters__button").removeClass('active');
                $(".filters__container").removeClass('active');
                $(this).toggleClass('active');
                targetFilter.toggleClass('active');
            }
        }
    });


    // ARTWORK

    if ($('.tabs-nav')) {

        $(".tabs-nav").each(function () {

            $(this).find("a").on('click', function (event) {
                event.preventDefault();
                $(this).closest('.tabs-nav').find('a').removeClass('active');
                // $('.tabs-nav > a').removeClass('active');
                $(this).addClass('active');

                let parentID = $(this).closest('.tabs-nav').attr('data-stage');
                $(parentID).find('.tab').hide();
                // $('.tabs-stage > div').hide();
                $($(this).attr('href')).show();
            });

            $(this).find("a:first").trigger('click');


        });

    }

    // if ($('form.cart').length) {
    //     $(window).on('scroll resize', function () {
    //         if ($(window).width() < 768) {
    //             if ($(window).scrollTop() > $('header').height() + 200) {
    //                 $('form.cart:not(.grouped_form), .type-product:not(.product-type-grouped) .offers').addClass('fixed');

    //                 if ($('form.cart').hasClass('fixed')) {
    //                     $('.content-area').css('margin-top', $('form.cart').outerHeight());
    //                 }

    //             }
    //             else {
    //                 $('form.cart:not(.grouped_form), .type-product:not(.product-type-grouped) .offers').removeClass('fixed');
    //                 $('.content-area').css('margin-top', 0);
    //             }
    //         }
    //         // } else {
    //         //     if ($(window).scrollTop() > $('.product-info-container .single_add_to_cart_button').offset().top + -122) {
    //         //         $('.product-info-container form.cart:not(.grouped_form), .type-product:not(.product-type-grouped) .offers').addClass('fixed');

    //         //         if ($('.product-info-container form.cart').hasClass('fixed')) {
    //         //             $('.content-area').css('margin-top', $('form.cart').outerHeight());
    //         //         }

    //         //     }
    //         //     else {
    //         //         $('.product-info-container form.cart:not(.grouped_form), .type-product:not(.product-type-grouped) .offers').removeClass('fixed');
    //         //         $('.content-area').css('margin-top', 0);
    //         //     }
    //         // }
    //     });
    // }



    $(".shortcode_wysija").addClass('clearfix');


    // SCROLLING TAGS
    if ($('.tags').length) {
        var totalWidth = 0;
        $('.tags p').each(function () {
            totalWidth += ($(this).outerWidth(true) + 200);
        });

        $('.tags').width(totalWidth);

        // var $horizontal = $('.tags');

        // $(window).on('scroll touch', function () {
        //     var s = $(this).scrollTop(),
        //         d = $(document).height(),
        //         c = $(this).height();

        //     scrollPercent = (s / (d - c));

        //     var position = (scrollPercent * ($(document).width() - $horizontal.width()));

        //     $horizontal.css({
        //         'left': position
        //     });
        // });
    }


    $('.artist-tabs-menu a').on('click', function (event) {
        event.preventDefault();

        $('.artist-tabs, .artist-tabs-menu a').removeClass('active');
        $('.artist-tabs').fadeOut('fast');

        $(this).addClass('active');
        if ($(window).width() > 992) {
            if ($('.js-four-card-carousel').length) {
                $('.js-four-card-carousel').slick('refresh');
            }
        }
        $($(this).attr('href')).fadeIn('slow', function () {
            if ($('.product-grid').length) {
                var $grid = $('.product-grid .products').masonry({
                    // options...
                    itemSelector: '.product-grid__item',
                });
                $grid.imagesLoaded().progress(function () {
                    $grid.masonry().masonry('destroy');
                    $grid.masonry().masonry('layout');
                });
            }

            if($('.artist-tabs-menu:not(.editions)').length) {
                if ($(window).width() > 992) {
                    $('html,body').animate({
                        scrollTop: $('.archive-banner').outerHeight() + $('.navbar').outerHeight() - $('.artist-menu').outerHeight() - 10
                    }, 1000);
                } else if($(window).width() <= 768 && $(window).width() >= 481) {
                    $('html,body').animate({
                        scrollTop: $('.archive-banner').outerHeight() + 60
                    }, 1000);
                }
                else {
                    $('html,body').animate({
                        scrollTop: $('.archive-banner').outerHeight() + 70
                    }, 1000);
                }
            }


        });
    });


    if ($('.term-description').children('p:not(:first-of-type)').length > 0) {
        $('.artist-description.read-more').on('click', function (e) {
            $(this).toggleClass('active');

            if ($(this).hasClass('active')) {
                $(this).text('See Less');
            } else {
                $(this).text('Read More');
            }
            $('.term-description').children('p:not(:first-of-type)').slideToggle();
            e.preventDefault();
        });
    } else {
        $('.artist-description.read-more').attr('style', 'display:none!important');
    }


    $('label[for=payment_method_worldpaydirect]').text('Credit/Debit Card');
    $('label[for=worldpaydirect-card-expiry]').text('Expiry');



    var framedItem = $(".framed-item");

    if (framedItem.length > 0) {
        $(".wcppec-checkout-buttons").hide();
    }


    // SIMPLE FRAMING
    var simpleFramed = $('#simpleframed');

    if (simpleFramed.length > 0) {
        if (simpleFramed.is(':checked')) {
            var edition = simpleFramed.attr('data-edition');
            $('#pa_edition').val(edition).change();
            $('.single_add_to_cart_button, .wcppec-checkout-buttons').hide();
            $('.simpleframebasket_button').show();
        } else {
            $('#pa_edition option:not(:contains(FRAMED))').attr('selected', 'selected').change();
            $('#pa_edition').val(edition).change();
            $('.single_add_to_cart_button, .wcppec-checkout-buttons').show();
            $('.simpleframebasket_button').hide();
        }

        simpleFramed.change(function () {
            if (this.checked) {
                var edition = $(this).attr('data-edition');
                $('#pa_edition').val(edition).change();
                $('.single_add_to_cart_button, .wcppec-checkout-buttons').hide();
                $('.simpleframebasket_button').show();
            } else {
                $('#pa_edition option:not(:contains(FRAMED))').attr('selected', 'selected').change();
                $('#pa_edition').val(edition).change();
                $('.single_add_to_cart_button, .wcppec-checkout-buttons').show();
                $('.simpleframebasket_button').hide();
            }
        });
    }



    $('.simpleframebasket_button').click(function (e) {
        if ($('input[name="variation_id"]').length > 0) {
            var vid = $('input[name="variation_id"]').val();
        } else {
            var vid = "";
        }
        if ($('input[name="product_id"]').length > 0) {
            var pid = $('input[name="product_id"]').val();
        } else {
            var pid = $('*[name="add-to-cart"]').val();
        }
        var data = {
            action: 'nelly_framing_frontend_add_framed',
            product_id: pid,
            variation_id: vid,
            frame_id: frame_id,
            frame_variation_id: $(this).attr('data-frame'),
        };
        $(this).addClass('processing');
        $(this).text('Adding to cart...')
        $.post('/wp-admin/admin-ajax.php', data, function (response) {
            $('#add_frame').removeClass('processing');
            document.location.href = '/basket';
        });
        e.preventDefault();
    });




    $('.simpleframebasket_button').click(function (e) {
        if ($('input[name="variation_id"]').length > 0) {
            var vid = $('input[name="variation_id"]').val();
        } else {
            var vid = "";
        }
        if ($('input[name="product_id"]').length > 0) {
            var pid = $('input[name="product_id"]').val();
        } else {
            var pid = $('*[name="add-to-cart"]').val();
        }
        var data = {
            action: 'nelly_framing_frontend_add_framed',
            product_id: pid,
            variation_id: vid,
            frame_id: frame_id,
            frame_variation_id: $(this).attr('data-frame'),
        };
        $(this).addClass('processing');
        $(this).text('Adding to cart...')
        $.post('/wp-admin/admin-ajax.php', data, function (response) {
            $('#add_frame').removeClass('processing');
            document.location.href = '/basket';
        });
        e.preventDefault();
    });




    $('.shows-nav li a').on('mouseenter', function () {
        $(this).parent('li').parent('ul').find('a').removeClass('active');
        $(this).addClass('active');
    }).on('mouseleave', function () {
        $(this).parent('li').parent('ul').find('a').removeClass('active');
        $(this).parent('li').parent('ul').find('[data-original="true"]').addClass('active');
    });


    $('.gallery-container li > img').each(function () {
        if (($(this).width() / $(this).height() >= 1) && !($(this).width() == $(this).height())) {
            $(this).addClass('landscape');
        } else {
            $(this).addClass('portrait');
        }
    });


    var hashTagActive = "";
    $(".scroll").on("click touchstart", function (event) {
        //if(hashTagActive != this.hash) { //this will prevent if the user click several times the same link to freeze the scroll.
        event.preventDefault();
        //calculate destination place

        var offset = 215;

        if ($(window).width() < 992) {
            offset = 100;
        }

        var dest = 0;
        if ($(this.hash).offset().top > $(document).height() - $(window).height()) {
            dest = $(document).height() - $(window).height();
        } else {
            dest = $(this.hash).offset().top - offset;
        }
        //go to destination
        $('html,body').animate({
            scrollTop: dest
        }, 2000, 'swing');
        //hashTagActive = this.hash;
        //}
    });


    // Animating text when scrolling into view
    $(document).on('scroll', function () {
        $('.text-animate').each(function () {
            $(this).css('opacity', '0');
            if (isScrolledIntoView($(this))) {
                $(this).addClass("animated fadeInUp slower");
            } else {
                $(this).removeClass("animated fadeInUp slower");
            }
        });

    }).trigger('scroll');


    $(window).on('resize scroll', artistScroll);
    $(document.body).on('touchmove', artistScroll);

    function artistScroll() {
        $('.artist-grid').each(function () {
            if ($(this).isInViewport(500)) {
                $(this).addClass("active");
            }
        });
    }

    var offsetAdd = 0;

    $.fn.isInViewport = function (offsetAdd) {
        let elementTop = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();

        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && (elementTop + offsetAdd) < viewportBottom;
    };

    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    function createCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    $('.manual-address').on('change', function() {
        var $checkbox = $(this).find('input[type=checkbox]');
        if($checkbox.prop("checked")) {
            $(this).parent().find('.address-field').fadeIn();
        } else {
            $(this).parent().find('.address-field').fadeOut();
        }
    });


    // Animating the nelly logo
    // var nellyLogo = document.getElementById('nelly-logo');
    // if (nellyLogo) {
    //     bodymovin.loadAnimation({
    //         container: nellyLogo, // the dom element that will contain the animation
    //         renderer: 'svg',
    //         loop: false,
    //         autoplay: true,
    //         path: '/wp-content/themes/nellyduff18/assets/animations/logo.json' // the path to the animation json
    //     });
    // }


    // Fading out inactive elements
    $('.card-carousel__card').on('mouseover', function () {
        $(this).parent('.slick-slide').siblings().find('.card-carousel__card').each(function () {
            $(this).addClass('hovered');
        });
        $(this).removeClass('hovered');
    }).on('mouseleave', function () {
        $(this).parent('.slick-slide').siblings().find('.card-carousel__card').each(function () {
            $(this).removeClass('hovered');
        });
    });

    $('.gallery img').on('mouseover', function () {
        $(this).closest('.gallery').find('img').each(function () {
            $(this).addClass('hovered');
        });
        $(this).removeClass('hovered');
    }).on('mouseleave', function () {
        $(this).closest('.gallery').find('img').each(function () {
            $(this).removeClass('hovered');
        });
    });


    // PRODUCT FILTERS
    var filtersTrigger = $(".filters-expand");
    var filtersContainer = $("#productFilters");
    var checkboxFilter = $(".wcpf-checkbox-item");
    var checkboxList = $(".wcpf-field-checkbox-list");

    function filtersDevices() {
        var win = $(this); //this = window
        if (win.width() >= 992) {
            filtersContainer.addClass("show");
        } else {
            filtersContainer.removeClass("show");
        }
    }

    closeFilters();

    $('.wcpf-field-item').on('click', function () {
        closeFilters($(this));
    });

    function closeFilters(elem) {
        $('.wcpf-field-item').not(elem).not('.wcpf-field-color-list').each(function () {
            var $closed = $(this).hasClass("wcpf-box-hide");
            if ($closed === false) {
                $(this).find(".field-input-container").slideUp(300);
                $(this).addClass('wcpf-box-hide');
            }
        });
    }

    $(document).click(function (event) {
        $('.wcpf-field-item').each(function () {
            var clickover = $(event.target);
            var _closed = $(this).hasClass("wcpf-box-hide");
            if (_closed === false && !clickover.closest(".wcpf-filter-inner").length) {
                $(this).find(".field-input-container").slideUp(300);
                $(this).addClass('wcpf-box-hide');
            }
        });

    });

    $('.wcpf-field-item').each(function () {
        var filter = $(this);
        if ($(filter).find('.wcpf-item').hasClass('checked')) {
            $(filter).addClass('filtered');
        } else {
            $(filter).removeClass('filtered');
        }
    });

    filtersDevices();

    // $(window).on('resize', function(){
    // 	filtersDevices();
    // })

    filtersContainer.on('show.bs.collapse', function () {
        filtersTrigger.text("Hide Filters");	// do something...
    });

    filtersContainer.on('hide.bs.collapse', function () {
        filtersTrigger.text("Show Filters");	// do something...
    });

    $('.gallery-controls-block').click(function () {
        $(this).addClass('gallery-controls-active');
        $('.gallery-controls-grid').removeClass('gallery-controls-active');
        $('.product-grid').addClass('grid-full');
        $('.product-grid').removeClass('grid-half');
        var $grid = $('.product-grid .products').masonry({
            // options...
            itemSelector: '.product-grid__item',
        });
        $grid.masonry('layout');
    });

    $('.gallery-controls-grid').click(function () {
        $(this).addClass('gallery-controls-active');
        $('.gallery-controls-block').removeClass('gallery-controls-active');
        $('.product-grid').addClass('grid-half');
        $('.product-grid').removeClass('grid-full');
        var $grid = $('.product-grid .products').masonry({
            // options...
            itemSelector: '.product-grid__item',
        });
        $grid.masonry('layout');
    });

    $('.gallery-controls-filter-toggle').click(function () {
        $('.mobile-filters').addClass('open');
        $('.mobile-filters-close').addClass('open');
        $('.mobile-filters .wcpf-layout-simple-box').addClass('open');
        $('.site-header').addClass('filters-open');
    });

    $('.mobile-filters-close').click(function () {

        $('.mobile-filters').removeClass('open');
        $('.mobile-filters-close').removeClass('open');
        $('.mobile-filters .wcpf-layout-simple-box').removeClass('open');
        $('.site-header').removeClass('filters-open');

    });

    function remove_filter_classes() {
        $('.wcpf-field-item').addClass('wcpf-box-hide');
    }

    remove_filter_classes();

    function add_filter_classes() {
        $('.wcpf-field-item').removeClass('wcpf-box-hide');
    }

    $(window).on('resize', function () {
        if ($(this).width() > 992) {
            $('.product-grid').removeClass('grid-half');
            $('.product-grid').removeClass('grid-full');
            $('.gallery-controls-block').removeClass('gallery-controls-active');
            $('.gallery-controls-grid').addClass('gallery-controls-active');

            remove_filter_classes();

        } else {
            add_filter_classes();
        }
    });


    if ($(".artist-menu.search").length) {

        // Cache selectors
        var lastId,
            topMenu = $(".artist-menu.search"),
            topMenuHeight = topMenu.outerHeight() + 230,
            // All list items
            menuItems = topMenu.find("a"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function () {
                var item = $($(this).attr("href"));
                if (item.length) { return item; }
            });

        // Bind to scroll
        $(window).scroll(function () {
            // Get container scroll position
            var fromTop = $(this).scrollTop() + topMenuHeight;

            // Get id of current scroll item
            var cur = scrollItems.map(function () {
                if ($(this).offset().top < fromTop)
                    return this;
            });
            // Get the id of the current element
            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;
                // Set/remove active class
                menuItems
                    .removeClass("active").filter("[href='#" + id + "']").addClass("active");
            }
        });
    }



    if ($(".artist-nav-alpha").length) {

        // Cache selectors
        var lastId,
            topMenu = $(".artist-nav-alpha"),
            topMenuHeight = topMenu.outerHeight() + 130,
            $artistHighlight = $("#artist-highlight"),
            // All list items
            menuItems = topMenu.find("a"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function () {
                var item = $($(this).attr("href"));
                if (item.length) { return item; }
            });

        // Bind to scroll
        $(window).scroll(function () {
            // Get container scroll position
            var fromTop = $(this).scrollTop() + topMenuHeight;

            // Get id of current scroll item
            var cur = scrollItems.map(function () {
                if ($(this).offset().top < fromTop)
                    return this;
            });
            // Get the id of the current element
            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;
                // Set/remove active class
                menuItems
                    .removeClass("active").filter("[href='#" + id + "']").addClass("active");

                if ($(window).width() > 767) {
                    var leftPos = menuItems.filter("[href='#" + id + "']").position().left - 20;
                    $artistHighlight.stop().animate({
                        left: leftPos
                    });
                } else {
                    var leftPos = menuItems.filter("[href='#" + id + "']").position().left - 15;
                    var currentScroll = $('.artist-nav-alpha-holder').scrollLeft();
                    $('.artist-nav-alpha-holder').get(0).scrollLeft += (leftPos - currentScroll);
                }
            }
        });
    }

    if ($(".artist-menu.genres").length) {

        // Cache selectors
        var lastId,
            topMenu = $(".artist-menu.genres"),
            topMenuHeight = topMenu.outerHeight() + 230,
            // All list items
            menuItems = topMenu.find("a"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function () {
                var item = $($(this).attr("href"));
                if (item.length) { return item; }
            });

        // Bind to scroll
        $(window).scroll(function () {
            // Get container scroll position
            var fromTop = $(this).scrollTop() + topMenuHeight;

            // Get id of current scroll item
            var cur = scrollItems.map(function () {
                if ($(this).offset().top < fromTop)
                    return this;
            });
            // Get the id of the current element
            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;
                // Set/remove active class
                menuItems
                    .removeClass("active").filter("[href='#" + id + "']").addClass("active");
            }
        });
    }

    setTimeout(function () {
        if ($('.single-product__view-room').length > 0) {

            var flexNav = $('.flex-control-nav');
            var addFlexNav = '<ol class="flex-control-nav flex-control-thumbs"></ol>';
            var galleryWrapper = $(".woocommerce-product-gallery__wrapper");

            if (flexNav.length > 0) {
                $('.flex-control-nav').append('<li id="js-view-in-room" class="view-room-link"><span>View in Room</span></li>');
                if ($('.single-product__video').length > 0) {
                    var video = $('.single-product__video')[0].outerHTML;
                    $('.single-product__video').remove();
                    $('.flex-control-nav').append(video);
                }

            } else {

                var galleryImage = $(".woocommerce-product-gallery__image").attr("data-thumb");
                galleryWrapper.after(addFlexNav);
                $(".flex-control-nav").append('<li><img src="' + galleryImage + '" draggable="false"></li>');
                $('.flex-control-nav').append('<li id="js-view-in-room" class="view-room-link"><span>View in Room</span></li>');
                galleryWrapper.addClass("flex-viewport").css("margin-top", "0");
            }
        }

        $('.flex-control-thumbs').find('li').on('touchend click', function () {
            if ($(this).is("#js-view-in-room")) {
                $(".flex-viewport").hide();
                $(".view-in-room-wrapper").show();
            }
            else if($(this).is('.single-product__video')) {
                $(".view-in-room-wrapper").hide();
                $(".flex-viewport").hide();
                $('.single-product__video-gallery').addClass('active').show();
                if ($('.vimeo-video').length > 0) {
                    player.play();
                }
                // $('.woocommerce-product-gallery__wrapper div').removeClass('flex-active-slide');
            } else {
                $(".flex-viewport").show();
                $(".view-in-room-wrapper").hide();
                $('.single-product__video-gallery').removeClass('active').hide();
            }
        });


    }, 300);


    $(".view-in-room-close").click(function () {
        $(".view-in-room-wrapper").hide();
        $(".flex-viewport").show();
    });

    $(".view-in-room-expand").click(function () {
        $(".view-in-room-scroll").toggleClass("expand");
    });


    $(".product h1").fitToWidth();



    // let groupedTitle = $(".grouped_h3");
    // let groupedContent = $(".grouped_form");

    // if (groupedTitle.length > 0 && groupedContent.length > 0) {
    //     groupedTitle.hide();
    //     $('[data-stage="#frame-stage"]').prepend('<li><a href="#frame-print" rel="nofollow">Choose Print Type</a></li>');
    //     $("#frame-stage").prepend('<div id="frame-print" class="tab"></div>');

    // }

    var showPreviewCarousel = document.querySelectorAll(".show-preview-carousel__wrap");
    if (showPreviewCarousel !== null) {
        for (var i = 0; i < showPreviewCarousel.length; i++) {
            $(showPreviewCarousel[i]).slick({
                dots: true,
                arrows: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            });

            $(showPreviewCarousel[i]).on('afterChange', function () {

                var indicators = $(".show-preview-carousel").find(".show-preview-carousel__indicators");
                console.log(indicators);
                var dataId = parseInt($('.slick-current').attr("data-slick-index")) + 1;
                console.log(dataId);

                indicators.find('span').text(dataId);
            });
        }
    }


    var $item = $('.woocommerce-grouped-product-list-item'), //Cache your DOM selector
    visible = 3, //Set the number of items that will be visible
    index = 0, //Starting index
    endIndex = ( $item.length / visible ) - 1; //End index

    $('.grouped-right').on('click',function(){
        if(index < endIndex ){
            index++;
            $item.css({'left':'-=278px'});
        }
    });

    $('.grouped-left').on('click',function(){
        if(index > 0){
        index--;            
        $item.animate({'left':'+=278px'});
        }
    });

    // GIFT MESSAGE CHECKOUT BOX
    const giftMessageCheckbox = document.querySelector("#gift_message_checkbox");
    const giftMessageTextarea = document.querySelector("#gift_message_textarea");

    if (giftMessageCheckbox !== null && giftMessageTextarea !== null) {
        giftMessageCheckbox.addEventListener('change', (event) => {
            if (event.currentTarget.checked) {
                giftMessageTextarea.classList.add("active");
            } else {
                giftMessageTextarea.classList.remove("active");
            }
        });
    }



    function pbfAdjustments() {
        var pbfContainer = document.getElementById("PBFPopup");
        var activeFinance = document.querySelector(".finance-active");
        var pbfPayment = document.querySelector(".wc_payment_method.payment_method_pbf_gateway");
        var paymentOptions = document.querySelectorAll(".wc_payment_method");
        var pbfInput = document.getElementById("payment_method_pbf_gateway");
        var pbfNotice = document.getElementById("pbf-checkout-message");
        var pbfCountryMessage = document.getElementById("pbf-country-message");
        var paymentMethods = document.getElementsByName("payment_method");
        var selectedCountry = document.getElementById("select2-billing_country-container");
        var orderButton = document.getElementById("place_order");
        var paymentEmpty = false;
        var i;

        if (paymentMethods !== null) {
            paymentMethods[0].checked = true;
        }

        if (pbfContainer !== null) {
            if (!pbfContainer.classList.contains("finance-active")) {
                
                if (pbfNotice !== null) {
                    pbfNotice.style.display = "none";
                }

                if (pbfCountryMessage !== null) {
                    pbfCountryMessage.style.display = "none";
                }
                
            } else {

                orderButton.innerHTML = "Place order with Own Art";

                if (pbfNotice !== null) {
                    pbfNotice.style.display = "block";
                }
    
                if (pbfCountryMessage !== null) {
                    if (selectedCountry.getAttribute("title") !== 'United Kingdom (UK)') {
                        orderButton.disabled = true;
                        orderButton.style.display = "none";
                        pbfCountryMessage.style.display = "block";
                    } else {
                        orderButton.disabled = false;
                        orderButton.style.display = "block";
                        pbfCountryMessage.style.display = "none";
                    }
                }
            }
        }
    }


    const orderReviewSection = document.querySelector("#order_review");

     if (orderReviewSection !== null) {

        pbfAdjustments();

        const checkoutWatch = new MutationObserver(function(mutations_list) {
            mutations_list.forEach(function(mutation) {
                pbfAdjustments();
            });
        });

        checkoutWatch.observe(orderReviewSection, {childList: true});
    }

    function countrySelectChange() {

        $('#billing_country').on('select2:select', function (e) {
            var data = e.params.data;
            var countryID = data.id;
            var orderButton = document.getElementById("place_order");
            var pbfCountryMessage = document.getElementById("pbf-country-message");
            var pbfContainer = document.getElementById("PBFPopup");

            if (pbfContainer.classList.contains("finance-active")) {
                if (countryID !== 'GB') {
                    orderButton.disabled = true;
                    orderButton.style.display = "none";
                    pbfCountryMessage.style.display = "block";
                    console.log("Country Check: Outside of UK");
                } else {
                    orderButton.disabled = false;
                    orderButton.style.display = "block";
                    pbfCountryMessage.style.display = "none";
                    console.log("Country Check: UK selected");
                }
            }
        });
    }

    countrySelectChange();
      
    
});

$.fn.fitToWidth = function() {
    $(this).wrapInner("<span style='display:inline;font:inherit;white-space:inherit;'></span>").each(function(){
        var $t=$(this);
        var a=$t.outerWidth(),
            $s=$t.children("span"),
            f=parseFloat($t.css("font-size"));
        while($t.children("span").outerWidth() > a && f > 36) $t.css("font-size",--f);
        console.log(f);
        if(f >= 36) console.log('here'); $t.css("white-space","inherit");
        $t.html($s.html());
    });
}