jQuery(document).ready(function($) {
	// MOBILE MENU
	function mobileMenuToggle() {
		$(".mobile-menu").toggleClass('active');
		$(".ajax-search").toggleClass('mobile-active');
		$(".mobile-menu__overlay").toggleClass('active');
		$("html").toggleClass('fixed');
	}


	$(".js-open-mobile-menu").on('click touchstart', function () {
		mobileMenuToggle();
	});

	$(".js-close-menu").on('click touchstart', function () {
		mobileMenuToggle();
	});

	$(".js-button-search, .menu-search").on('click', function (e) {
		$(this).toggleClass('active');
		$('.ajax-search').removeClass('active');

		$('.search-form').toggleClass('active');

		if ($('.search-form').hasClass('active')) {
			setTimeout(function () {
				$('.ajax-search').addClass('active');
			}, 800);
			$('.search-field').focus();
		}
		jQuery('.ajax-search-results .ajax-list').detach();
		e.preventDefault();
	});


	function mobileSwipe() {

		if ($(window).width() < 992) {
			$(".mobile-menu").swipe({
				swipeUp: function (event, direction, distance, duration, fingerCount) {
					mobileMenuToggle();
				},
				threshold: 0
			});

			// $(".site-header").swipe( {
			//     swipeDown:function(event, direction, distance, duration, fingerCount) {
			//         mobileMenuToggle();
			//     },
			//     threshold:0
			// });
		}
	}

	mobileSwipe();

	$(window).resize(function () {
		mobileSwipe();
	});


	$(".menu-toggle").on('click touchstart', function () {
		$(".mobile-menu .mobile-menu__button").each(function () {
			$(this).removeAttr('href');
		});

		setTimeout(function () {
			$(".mobile-menu .mobile-menu__button").each(function () {
				var link = $(this).attr('data-link');
				$(this).attr("href", link);
			});
		}, 500);
	});

	$(".mobile-menu__button-arrow").click(function () {
		$(".mobile-menu .mobile-menu__button").each(function () {
			$(this).removeAttr('href');
		});
	});
});